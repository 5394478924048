const colorBlack = "#000000";
const colorWhite = "#ffffff";
const colorTorchRed = "#FF0032";
const colorAzureRadiance = "#0077FF";
const colorChartreuseYellow = "#E7FF00";
const colorHollywoodCerise = "#FC0099";
const colorElectricGreen = "#12F306";
const colorWeekendBlueLight = "#64ABFC";

interface Colors {
    [colorName: string]: [ string , string ]
}

export const COLOR_ENUM = {
    BLACK: colorBlack,
    WHITE: colorWhite,
    RED: colorTorchRed,
    BLUE: colorAzureRadiance,
    BLUE_LIGHT: colorWeekendBlueLight,
    YELLOW: colorChartreuseYellow,
    PINK: colorHollywoodCerise,
    GREEN: colorElectricGreen
}

const colors : Colors = {
    "blauw": [colorAzureRadiance, colorElectricGreen],
    "groen": [colorElectricGreen, colorAzureRadiance],
    "roze": [colorHollywoodCerise, colorChartreuseYellow],
    "geel": [colorChartreuseYellow, colorBlack],
    "wit": [colorWhite, colorBlack],
    "rood": [COLOR_ENUM.RED, COLOR_ENUM.BLACK],
    "zwart": [colorBlack, colorWhite]
};

export const getContrastColorFromName = (name) => {
    if (typeof name === 'undefined' || name === null) return colors['wit'][1];
    return colors[name.toLowerCase()] ? colors[name.toLowerCase()][1] : colors['wit'][1];
}


export const getBackgroundColorFromName = (name) => {
    if (typeof name === 'undefined' || name === null) return colors['wit'][0];
    return colors[name.toLowerCase()] ? colors[name.toLowerCase()][0] : colors['wit'][0];
}

// export default Color;
